const Loading = () => {
    return (
        <div id="preloader">
        <div className="textload">Loading</div>
        <div id="status">
          <div className="spinner"></div>
        </div>
      </div>
    )
}

export default Loading