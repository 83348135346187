import React from 'react'
import Womanbanner from '../assets/images/Womanbanner.png'
import Terminos1 from '../assets/images/Terminos1.png'
import Ubicacion from '../assets/images/Ubicacion.png'
import {Link} from 'wouter'
import {PhoneIcon,MapIcon} from './Icons/Icons.js'

var today = new Date();
function footer() {
    return (
        <footer className="footer inverse-wrapper">
            <div className="container inner">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="widget">
                            <h3 className="widget-title">MAS INFORMACIÓN</h3>
                            <ul className="post-list">
                            <li>
                                <div className="icon-overlay"> <Link href="/"><span className="icn-more"></span><img src={Womanbanner} alt=""/> </Link> </div>
                                <div className="meta">
                                <h5><Link href="/">WomanTerrassa.com</Link></h5>
                                </div>
                            </li>
                            <li>
                                <div className="icon-overlay"> 
                                    <Link href="aviso-legal">
                                        <span className="icn-more"></span>
                                        <img src={Terminos1} alt=""/>
                                    </Link>
                                </div>
                                <Link href="aviso-legal"></Link>
                                <div className="meta">
                                    <Link href="aviso-legal"></Link>
                                    <h5><Link href="aviso-legal"></Link><Link href="aviso-legal">Terminos de uso</Link></h5>
                                </div>
                            </li>
                            <li>
                                <div className="icon-overlay"> <a href="https://goo.gl/maps/ND6hK1dmjMT8M4jDA" target="_blank" rel="noopener noreferrer"><span className="icn-more"></span><img src={Ubicacion} alt=""/> </a> </div>
                                <div className="meta">
                                <h5><a href="https://goo.gl/maps/ND6hK1dmjMT8M4jDA">Ubicación</a></h5>
                                </div>
                            </li>
                            </ul>
                        </div>
                    </div>
                
                    <div className="col-sm-4">
                        <div className="widget">
                            <h4 className="widget-title" id="ubicacion">Mapa</h4>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.4098678366076!2d2.019437615431842!3d41.58201507924677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a492d3691a4c03%3A0xb661bb78ad055471!2sCarrer+de+Perpiny%C3%A0%2C+17%2C+08226+Terrassa%2C+Barcelona!5e0!3m2!1ses!2ses!4v1480250298986" title="maps" frameBorder="0" style={{border:0}} allowFullScreen=""></iframe>
                        </div>
                
                        <div className="widget"></div>
                    </div>
                
                    <div className="col-sm-4">

                        <div className="widget" id="contactos">
                            <h4 className="widget-title">WomanTerrassa.com</h4>
                            <p>Un lugar al servicio de la diversión, donde deja volar la imaginacion en compañia de señoritas siempre dispuestas a complacer todos sus deseos. Ademas disponemos para su comodidad de Parking.Un local unico en su genero y diferente para su disfute y Relax.</p>
                            <div className="contact-info"> 
                                <MapIcon className="icon-centered margin-right-5px"/>
                                <a href="https://goo.gl/maps/ND6hK1dmjMT8M4jDA" target="_blank" rel="noopener noreferrer">Carrer de Perpinyà Nº 17  - 08226 Terrassa</a> 
                                <br />
                                <div><PhoneIcon className="icon-centered margin-right-5px"/><a href="tel:+34935156379">935156379 </a></div>
                            </div>
                        </div>
                
                    </div>
                
                </div>
            </div>
            
            <div className="sub-footer">
                <div className="container inner">
                    <p className="text-center">© {today.getFullYear()}
                        <Link href="/"> WomanTerrassa.com</Link> | 
                        <Link href="aviso-legal"> Derechos Reservados </Link>|<Link href="aviso-legal"> Terminos de uso</Link>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default footer
