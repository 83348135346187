import React from 'react'
import ImgHeader from '../assets/images/Header/header.jpg'

function AvisoLegal() {
    window.scrollTo(0,0);
    return (
        <div>
            <div className="post-parallax parallax inverse-wrapper parallax1" style={{backgroundImage:`url(${ImgHeader})`}}>
                <div className="container inner text-center">
                    <div className="headline text-center">
                        <h2 className="shadow-text-pink">Aviso Legal y Términos de Uso</h2>
                        <p className="lead shadow-text-blue">Woman Terrassa</p>
                    </div>
                </div>
            </div>

            <div className="light-wrapper">
                <div className="container inner">
                    <div className="row margin-top-50">
                        <div className="col-sm-5">
                            <figure><img src="https://makeitworkvalencia.files.wordpress.com/2013/06/pr1501.jpg?w=500&h=542" alt="" /></figure>
                        </div>
                        <div className="col-sm-7">
                        <h3 className="section-title">Aviso Legal y Términos de Uso</h3>
                        <p>
                                INFORMACIÓN LEGAL<br />

                                Conforme a la ley 34/2002 sobre Servicios de la Sociedad de la Información 

                                y Comercio Electrónico (LSSI), informamos de nuestros datos legales:<br /><br />

                                Titular: Jaume Durban Margarit<br />

                                NIF: 39676363Y<br />

                                Domicilio social: C/ Perpinya, 17 - 08226 - TERRASSA (Barcelona)<br />

                                Tel: (+34) 93 735 40 19<br /><br />

                                Derechos de propiedad intelectual e industrial y r esponsabilidades: 

                                Este Sitio Web se rige por las leyes españolas, y se sencuentra protegido 

                                por legislación nacional e internacional sobre propiedad intelectual e 

                                industrial.<br /><br />

                                Todos los contenidos que se muestran y en especial, artículos, estudios 

                                comerciales, diseños, textos, gráficos, logos, iconos, código en lenguaje 

                                PHP, ASP, HTML FLASH, Javascript, CSS, software, nombres comerciales, 

                                marcas, dibujos industrial y comercial están sujetos a derechos de 

                                propiedad intelectual e industrial de titular y/o de terceros que han 

                                autorizados debidamente su inclusión en el Sitio Web o son de dominio 

                                público.<br /><br />

                                El Titular del Sitio Web es el único PROPIETARIO Y RESPONSABLE de los 

                                contenidos de su sitio tanto en cuestiones legales como también en 

                                cuestiones de copyrights y derechos de autor por los textos, imágenes y 

                                gráficos allí publicados.<br /><br />

                                El uso no autorizado de los materiales e información contenida en el Sitio 

                                Web puede suponer la violación de la legislación sobre propiedad 

                                intelectual o industrial y de otras leyes aplicables. <br /><br />

                                Por su temática y contenido de alto valor erótico, esta página está 

                                totalmente prohibida a menores, por lo que se declina toda responsabilidad 

                                si se realizara un mal uso de la misma o alguien se siente herido en su 

                                sensibilidad. El Titular declara que todas las fotos e imágenes que 

                                aparecen en la web están realizadas con modelos mayores de edad y bajo su 

                                total consentimiento.<br /><br />

                                EL Usuario deberá abstenerse en todo caso de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistemas de seguridad que puedan estar instalados en la misma.</p>
                                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvisoLegal
