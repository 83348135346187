import './App.css';
import './main.css';
import React, { Suspense }  from 'react'
import Header from './components/header'
import Footer from './components/footer'
//import Home from './pages/Home'
import AvisoLegal from './pages/AvisoLegal'
import {Switch, Route} from 'wouter';
/*import { render } from 'react-dom';*/
import WomanTerrassa from './assets/images/welcome.png';
import CookieConsent from "react-cookie-consent";
import Loading from './components/Loading';
import ScrollButton from './components/ScrollButton';

const Home = React.lazy(() => import('./pages/Home'));


function App() {
  return (
    <main className="body-wrapper">
      <Header/>
      <Suspense fallback={
       <Loading />
       }>
      <Switch>
        <Route path="/" component={Home} />
        <Route path="/aviso-legal" component={AvisoLegal} />
        <Route path="/:hash">
          {(params) => <Home hash={params.hash} />}
        </Route>
      </Switch>
      </Suspense>
      <Footer/>
      <ScrollButton/>
      <CookieConsent 
        location="bottom" 
        cookieName="accept-coockie" 
        expires={999}
        contentClasses="animate__animated animate__fadeInDown"
        style={{
          backgroundColor: "black",
          height: "100%",
          zIndex: "200",
          width: "100%",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          alignContent: "center"
        }}
        buttonText="Entrar"
        enableDeclineButton 
        declineButtonText="Salir"
        hideOnDecline={false}
        setDeclineCookie={false}
        flipButtons
        buttonWrapperClasses="CookieButtonsWrapper animate__animated animate__fadeInUp"
        onDecline={() => {
          window.location.href = "https://www.google.com/search?q=womanterrassa";
        }}
        overlay>
        <img src={WomanTerrassa} title="Woman Terrassa" alt="Woman Terrassa" draggable="false" /> <br /><br />
        Para ver el contenido de este web tiene que ser mayor de edad. Si usted es menor de edad pulse SALIR para abandonar este sitio.
        <br /> 
        Accediendo a este sitio usted declara que no facilitará el material que aparece en este web a ningún menor.
        <br /><br /><br />
      </CookieConsent>
    </main>
  );
}
/*render(<App />, document.getElementById("root"));*/


export default App;
