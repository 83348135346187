import React, { useEffect } from 'react'
import logo from '../assets/images/logo/logo.png'
import { Link } from 'wouter'

function Header() {

  // const [ location ,setLocation ] = useLocation();

  // const handleAvisoLegalClick = (path) => {
  //   let x = 0, y = 0;
  //   setLocation(path);

  //    window.scrollTo(x,y);
  // }

  window.addEventListener('scroll', function(e){
    var w = window.innerWidth;
        
    var distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 100,
      header = document.querySelector(".navbar");
      if ((distanceY > shrinkOn) && w > 991) {
        header.classList.add("fixed");
      } else {
        if (header.classList.contains("fixed")) {
          header.classList.remove("fixed");
        }
      }
    });

    useEffect(() => {
        var btn = document.querySelector(".btn.responsive-menu");
        var btn_menu = document.querySelector(".navbar .navbar-collapse");
        btn.addEventListener('click',function(){
          if(!this.classList.contains("opn")){
            this.classList.add("opn");
            if(!this.classList.contains("in")){
              btn_menu.classList.add("in");
            }
          }else{ 
            this.classList.remove("opn");
            if(btn_menu.classList.contains("in")){
              btn_menu.classList.remove("in");
            }
          }
        });

        return () => {
          btn.removeEventListener('click',function(){
            if(!this.classList.contains("opn")){
              this.classList.add("opn");
              if(!this.classList.contains("in")){
                btn_menu.classList.add("in");
              }
            }else{ 
              this.classList.remove("opn");
              if(btn_menu.classList.contains("in")){
                btn_menu.classList.remove("in");
              }
            }
          });
        }
    },[])

    return (
        <div className="navbar animate__animated animate__fadeInDown">
            <div className="navbar-header">
                <div className="basic-wrapper"> 
                    <div className="navbar-brand"> 
                        <Link href="/" title="" alt="">
                            <img src="/" srcSet={logo} className="logo-light" draggable="false" alt=""/>
                            <img src="/" srcSet={logo} className="logo-dark" draggable="false" alt=""/>
                        </Link> 
                    </div>
                    <div className="btn responsive-menu" data-toggle="collapse" data-target=".navbar-collapse"><i></i></div>
                </div>
            </div>
            <nav className="navbar-collapse collapse padding-bottm-5px">
                <ul className="nav navbar-nav">
                    <li><Link href="/">Página Principal</Link></li>
                    <li><Link href="/instalaciones">Instalaciones</Link></li>
                    <li><Link href="/ubicacion">Ubicación</Link></li>
                    <li><Link href="/aviso-legal">Aviso Legal</Link></li>
                    <li><a href="#contactos" className="dropdown-toggle js-activated">Contactos</a></li>
                </ul>
            </nav>
        </div>
    )
}

export default Header
